/* global DYNAMIC_YIELD_DYNAMIC_URL */
/* global DYNAMIC_YIELD_STATIC_URL */

import React from 'react';
import Head from 'next/head';
import { HomepageApp } from 'components/homepage/HomeApp/HomeApp';
import metadata from 'shared/constants/meta-tags';
import { Provider } from 'react-redux';
import store from 'redux/store';

const HomePage = () => (
  <>
    <Head>
      <title>{metadata.home.title}</title>
      <meta property="og:title" content={metadata.home.title} key="title" />
      <meta name="description" content={metadata.home.description} key="description" />
      <script
        id="dynamic-yield-script"
        type="text/javascript"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.DY = window.DY || {};
            DY.recommendationContext = { type: 'HOMEPAGE' };
          `
        }}
      />
      <script type="text/javascript" src={`${DYNAMIC_YIELD_DYNAMIC_URL}`} />
      <script type="text/javascript" src={`${DYNAMIC_YIELD_STATIC_URL}`} />
    </Head>
    <Provider store={store}>
      <HomepageApp />
    </Provider>
  </>
);

export default HomePage;
